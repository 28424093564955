<template>
  <div>
    <div class="_background" v-if="backgroundSettings?.media">
      <Media v-bind="backgroundSettings.media" />
      <div
        v-if="hasBackdrop()"
        class="_backdrop"
        :class="`-backdrop-${backgroundSettings?.backdrop}`"
      ></div>
    </div>

    <div
      :class="`container ${theme?.container ? `-${theme.container}` : ''} ${theme?.height ? `-height-${theme.height}` : ''} ${theme?.verticalPadding ? `-v-padding-${theme.verticalPadding}` : ''}`"
    >
      <div
        :id="advanced?.id ?? ''"
        :class="`grid-${layout} ${theme?.verticalAlign ? `-align-${theme.verticalAlign}` : ''} ${theme?.gapX ? `-gap-x-${theme.gapX}` : ''} ${theme?.gapY ? `-gap-y-${theme.gapY}` : ''} ${advanced?.class ?? ''}`"
      >
        <GridArea
          v-for="i in areasCount"
          :i="i - 1"
          :width="calculateWithFromLayout(i)"
        >
          <div
            v-for="block in areas[i - 1]"
            :key="block.id"
            class="blocks_block"
          >
            <Block :block="block" />
          </div>
        </GridArea>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { __unique } from '@lotsof/sugar/array'
import GridArea from '~/components/system/gridArea/gridArea.vue'
import Block from '../block/block.vue'
import './grid.css'
import { type TGrid } from './grid.type.js'
import Media from '~/components/ui/media/media.vue'
import type { TBlock } from '~/components/system/block/block.type'

const props = withDefaults(defineProps<TGrid>(), {
  layout: '12',
})

function calculateWithFromLayout(i: number) {
  const columnsCount = __unique(props.layout.split('')).length
  let areaCountInRow = 0
  const rows = props.layout.split('_')
  for (let [j, row] of rows.entries()) {
    const areas = row.split('').filter((r) => r !== ' ')
    const countInRow = areas.filter((a) => {
      return parseInt(a) == i
    })
    if (countInRow.length > areaCountInRow) {
      areaCountInRow = countInRow.length
    }
  }
  return Math.round((100 / columnsCount) * areaCountInRow)
}

const areasCount = __unique(props.layout?.split('')).length
const areas = [props.area1, props.area2, props.area3, props.area4]

const hasBackdrop = () => {
  return computed(
    () =>
      props.backgroundSettings?.backdrop &&
      props.backgroundSettings?.backdrop !== 'none',
  )
}
</script>
