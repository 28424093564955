<template>
  <div :class="`grid_area grid_area-${i}`">
    <slot />
  </div>
</template>
<script lang="ts" setup>
import { provide } from 'vue'
import { type TGridArea } from './gridArea.type'
import './gridArea.css'

const props = withDefaults(defineProps<TGridArea>(), {
  i: 0,
})

provide('grid', {
  area: props.i,
  width: props.width,
})
</script>
